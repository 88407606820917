import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

/**
 * Get the key from the `data` namespace
 *
 * Updates output when the language is changed
 * @param Type The type from the I18n key
 * @param key I18n key from data.json
 * @returns Object
 */

export default function useI18nData<Type>(key: string) {
  const {i18n, t} = useTranslation();
  const [data, setData] = useState(
    t(key, {returnObjects: true, ns: 'data'}) as Type,
  );

  useEffect(() => {
    setData(t(key, {returnObjects: true, ns: 'data'}) as Type);
  }, [i18n.language, t, key]);

  return data;
}
