import {CSSStyles} from '../../../types/CSSStyles';
import useAppTheme from '../../design_system/hooks/useAppTheme';
import useMediaQueryGetter, {
  MediaQuery,
} from '../../design_system/hooks/useMediaQueryGetter';
import Flex from '../flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly background: React.ReactNode;
  readonly copy?: React.ReactNode;
  readonly rootStyle?: CSSStyles;
}

export default function MarketingHero(props: Props): JSX.Element {
  const {background, copy, rootStyle} = props;
  const getMediaQuery = useMediaQueryGetter();
  const {sizes, spacing} = useAppTheme();

  return (
    <div
      css={[
        css({
          height: 650,
          overflow: 'hidden',
          position: 'relative',
          maxWidth: sizes.maxWidth,
          width: '100%',
        }),
        rootStyle,
      ]}
    >
      <Flex
        sx={css({
          height: '100%',
          justifyContent: 'center',
          pointerEvents: 'none',
          position: 'absolute',
          width: '100%',
          zIndex: 0,
        })}
      >
        {background}
      </Flex>

      <div
        css={css({
          display: 'grid',
          gridTemplateColumns: '1fr auto 3fr',
          gridTemplateRows: '2fr auto 1fr',
          height: '100%',
          width: '100%',

          [getMediaQuery(MediaQuery.SmallScreen)]: {
            gridTemplateColumns: `${spacing.x8} auto ${spacing.x8}`,
          },
        })}
      >
        <div
          css={css({
            gridColumn: '1 / span 3',
          })}
          key="row-1"
        />
        <div
          css={css({
            gridColumn: '2 / span 1',
            zIndex: 1,
          })}
          key="row-2-copy"
        >
          {copy}
        </div>
        <div
          css={css({
            gridColumn: '1 / span 3',
          })}
          key="row-3"
        />
      </div>
    </div>
  );
}
