import useAppTheme from '../design_system/hooks/useAppTheme';
import Separator from '../design_system/icon/Separator';
import {ContentColor} from '../design_system/theme/useContentPalette';
import Typography, {Size} from '../design_system/typography/Typography';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly subTitle: string;
  readonly title: string;
}

export default function SectionTitle(props: Props): JSX.Element {
  const {subTitle, title} = props;
  const {spacing} = useAppTheme();

  return (
    <Flex
      sx={css({
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      })}
    >
      <Typography
        color={ContentColor.PRIMARY_SUBDUED}
        fontFamily="GARAMOND"
        size={Size.XXL}
        weight={700}
      >
        {title}
      </Typography>
      <Flex
        sx={css({
          gap: spacing.x8,
        })}
      >
        <Separator />
        <Typography size={Size.M}>{subTitle}</Typography>
        <Separator />
      </Flex>
    </Flex>
  );
}
